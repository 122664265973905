import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const FAQ = () => (
  <HowToLayout>
    <h2>FAQ</h2>
  </HowToLayout>
);

export default FAQ;
